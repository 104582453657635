<div class="d-flex my-auto me-1 align-content-center" id="tags">
  <div
    *ngFor="let tag of tags"
    [ngStyle]="{ 'background-color': tag.color, border: 'none', color: tag.contrastColor, 'min-width': '70px', height: '28px' }"
    class="badge me-1 d-flex justify-content-center"
  >
    <span class="align-content-center">{{ tag.name }}</span>
    <i
      (click)="selectTag(tag)"
      *ngIf="!tag.isDefault"
      class="bi-x remove-tag-button float-end cursor-pointer align-content-center"
      role="none"
    ></i>
  </div>

  <button [matMenuTriggerFor]="tagMenu" class="btn btn-secondary tag-button"><i class="bi-tag"></i></button>
  <mat-menu #tagMenu="matMenu" (closed)="newTagName = ''; filterTags('')">
    <div (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()" class="d-flex mb-1 mx-1" role="none">
      <input
        (ngModelChange)="filterTags($event)"
        [(ngModel)]="newTagName"
        [maxLength]="25"
        class="form-control"
        placeholder="Search/Create tag"
        type="text"
      />
      <input [(ngModel)]="newTagColor" class="cursor-pointer my-auto mx-1" type="color" />
      <button (click)="addTag()" [disabled]="!newTagName" class="btn btn-primary"><i class="bi-plus"></i></button>
    </div>
    <div *ngFor="let tag of filteredTags" class="mb-1 ms-1">
      <div *ngIf="!editingTag || editingTag !== tag" class="d-flex justify-content-between">
        <button
          (click)="selectTag(tag)"
          [ngStyle]="{ 'background-color': tag.color, color: tag.contrastColor, height: '28px' }"
          class="badge border-0"
        >
          {{ tag.name }}
        </button>
        <div *ngIf="tag.uid !== 'tag_defaultLockedTag'" class="me-1">
          <button
            (click)="$event.stopPropagation(); editTagName = tag.name; editTagColor = tag.color; editingTag = tag"
            class="btn btn-primary me-1"
            style="font-size: small; padding: 2px 7px"
          >
            <i class="bi-pencil"></i>
          </button>
          <button (click)="deleteTag(tag)" class="btn btn-danger" style="font-size: small; padding: 2px 7px">
            <i class="bi-trash"></i>
          </button>
        </div>
      </div>
      <div
        (click)="$event.stopPropagation()"
        (keydown)="$event.stopPropagation()"
        *ngIf="editingTag === tag"
        class="d-flex mb-1"
        role="none"
      >
        <input [(ngModel)]="editTagName" [maxLength]="25" class="form-control" type="text" />
        <input [(ngModel)]="editTagColor" class="cursor-pointer mx-1 my-auto" type="color" />
        <button (click)="updateTag(tag)" [disabled]="!editTagName || !editTagColor" class="btn btn-primary">
          <i class="bi-check"></i>
        </button>
      </div>
    </div>
  </mat-menu>
</div>
