import { Injectable } from '@angular/core';
import { ApiBase } from './ApiBase';
import { Observable } from 'rxjs';
import { GeneratedSchema, GeneratedSchemaUpdateLog } from '../types/generated';

@Injectable({ providedIn: 'root' })
export class SchemaEndpoints extends ApiBase {
  public getSchema(): Observable<GeneratedSchema> {
    return this.get<GeneratedSchema>(`/game/${this.gameId}/schema`);
  }

  updateSchema(inputJson: Array<unknown>) {
    return this.put<GeneratedSchemaUpdateLog[]>(`/game/${this.gameId}/schema`, inputJson);
  }
}

export * from './schema/EnumTypeEndpoints';
export * from './schema/FieldEditorEndpoints';
export * from './schema/SelectTypeEndpoints';
export * from './schema/StructTypeEndpoints';
