import { Entity } from './Entity';
import { DeserializedFieldValueType, FieldValue } from './helpers/FieldValue';
import { AutoSave } from '../decorators/AutoSave';
import { GeneratedDataInstance } from '../types/generated';
import { StructType } from './StructType';
import GTInjector from '../GTInjector';
import { DataInstanceRepository, StructTypeRepository } from '@services/repositories';
import { Dummy, Logger, Try } from '../utils';
import { FieldType } from '@services/entities/helpers';
import { ShouldPersist } from '@services/types/ShouldPersist';
import { Tag } from '@services/entities/Tag';

export interface NewDataInstance {
  uid: string;
  dataType: string;
  fieldValues: Record<string, FieldValue>;
  structType: StructType;
  subObjects?: string[];
  tags: Tag[];
  modified: string;
}

@AutoSave()
export class DataInstance extends Entity implements ShouldPersist {
  public readonly dataType: string;
  public readonly structType: StructType;

  /**
   * The value of this record can be null, but in cases where it is required according to the struct type, it will always
   *  exist and will not be null. In those cases you can assert the type by using a bang (!).
   */
  public readonly fieldValues: Record<string, FieldValue | null>;
  public shouldPersist = false;

  /**
   * A random identifier that can be used to identify this instance in the UI. As using .identifier will not always work
   * as that returns a promise, but angular change detection does not work with promises, so this is a workaround.
   */
  public readonly randomIdentifier: string;
  public tags: Tag[] = [];
  public readonly modified: string;
  private subObjects: string[] = [];
  private _uid: string;

  constructor({ uid, dataType, fieldValues, structType, subObjects, tags, modified }: NewDataInstance) {
    super();

    this._uid = uid;
    this.dataType = dataType;

    this.fieldValues = new Proxy(fieldValues, {
      get<T extends Record<string, FieldValue | null>>(target: T, k: keyof T): FieldValue | null {
        if (k in structType.fields) return target[k] ?? null;
        throw new Error(
          `Field '${String(k)}' could not be found for data instance ${uid}. Available fields: ${Object.keys(structType.fields)
            .map((r) => `'${r}'`)
            .join(', ')}`,
        );
      },
    });

    this.structType = structType;

    if (subObjects) {
      this.subObjects = subObjects;
    }

    this.tags = tags;
    this.modified = modified;

    this.randomIdentifier = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  /**
   * @deprecated Use .identifier instead where possible
   */
  public get __uid() {
    return this._uid;
  }

  /**
   * Warning! If you use this .identifier method, you MUST make sure that the entity is persisted, or shouldPersist is
   *  set to true. Otherwise, it will cause very strange behaviour. Note that if you have an instance directly from the
   *  backend, it is always persistent and this method is safe to use.
   */
  public override get identifier(): Promise<string> {
    if (!this.shouldPersist && !this.isPersisted()) {
      throw new Error('Cannot get the identifier of an un-persisted entity');
    }

    if (this.shouldPersist && !this.isPersisted()) {
      return GTInjector.inject(DataInstanceRepository).then((dir) => dir.persist(this).then(() => this._uid));
    }

    return Promise.resolve(this._uid);
  }

  public static async deserialize(data: GeneratedDataInstance): Promise<DataInstance> {
    const structType = await (await GTInjector.inject(StructTypeRepository)).get(data.dataType);
    if (structType === null) throw new Error(`Unable to find struct type ${data.dataType}`);

    const fieldValues = (
      await Promise.all(
        Object.values(data.fieldValues)
          .filter(Boolean)
          .map((fv) => FieldValue.deserialize(fv, structType, data.uid)),
      )
    ).reduce(
      (acc, fv) => {
        acc[fv.field.fieldId] = fv;
        return acc;
      },
      {} as Record<string, FieldValue>,
    );

    const dataInstance = new DataInstance({
      uid: data.uid,
      dataType: data.dataType,
      fieldValues: fieldValues,
      subObjects: data.subObjects ? Object.keys(data.subObjects) : undefined,
      structType,
      tags: await Promise.all(data.tags.map((tag) => Tag.deserialize(tag))),
      modified: data.modified,
    });

    dataInstance.shouldPersist = true;
    await dataInstance.updateLastSavedValueHash();

    return dataInstance;
  }

  /**
   * @deprecated This method should be rarely used by a developer because @AutoSave() should handle most cases. If you
   * find any cases where auto saving does not work, please open a bug report so it can be fixed.
   */
  public async save() {
    return (await GTInjector.inject(DataInstanceRepository)).save(this);
  }

  public async serialize(): Promise<Readonly<GeneratedDataInstance>> {
    return Object.freeze({
      uid: await this.identifier,
      dataType: this.dataType,
      fieldValues: await Promise.all(
        Object.values(this.fieldValues)
          .filter(Boolean)
          .filter((fv) => fv!.shouldPersist)
          .map((fv) => fv!.serialize()),
      ),
      tags: await Promise.all(Object.values(this.tags).map((tag) => tag.serialize())),
      modified: this.modified,
    });
  }

  public getName(): string {
    // TODO: Eventually we should have this as a variable on this class, so it can be updated dynamically when the
    //  field values change. For now though, this method is fine.

    return (
      Try(() => this.fieldValues['name']?.getDeserializedValue(FieldType.STRING, this.fieldValues['name'].value)) ||
      Try(() => this.fieldValues['title']?.getDeserializedValue(FieldType.STRING, this.fieldValues['title'].value)) ||
      Try(() => this.fieldValues['displayName']?.getDeserializedValue(FieldType.STRING, this.fieldValues['displayName'].value)) ||
      `${this.dataType} (${this.isPersisted() ? this._uid.split('_').at(-1)!.slice(0, 6) : this._uid})`
    );
  }

  public async getParent() {
    const dataInstanceRepository = await GTInjector.inject(DataInstanceRepository);
    return dataInstanceRepository.getParent(this);
  }

  /**
   * Sets the value of the field with the given name.
   *
   * @deprecated Avoid using this method where possible, use `DataInstance.fieldValues[field].set(...)` instead.
   * This is because angular change detection is more efficient when reading from a record.
   *
   * @param field the name of the field to set
   * @param value the new value of the field
   * @returns a promise that resolves when the value has been set
   */
  public async setFieldValue(field: string, value: DeserializedFieldValueType) {
    if (field in this.fieldValues) {
      return await this.fieldValues[field]!.set(value);
    }

    if (!(field in this.structType.fields)) {
      throw new Error(`Field ${field} does not exist in struct type ${this.structType.typeId}`);
    }

    this.fieldValues[field] = new FieldValue({
      dataInstanceUid: await this.identifier,
      field: this.structType.fields[field],
      value: '',
    });

    this.fieldValues[field]!.shouldPersist = true;

    try {
      return await this.fieldValues[field]!.set(value);
    } catch (e) {
      Logger.error(e);
      delete this.fieldValues[field];
      throw e;
    }
  }

  public async getSubObjects() {
    const dataInstanceRepository = await GTInjector.inject(DataInstanceRepository);
    return await Promise.all(this.subObjects.map((uid) => dataInstanceRepository.get(uid)));
  }

  public isPersisted() {
    return !Dummy.is(this._uid);
  }

  public setUid(uid: string) {
    if (this.isPersisted()) throw new Error('Cannot change the uid of a persisted entity');
    this._uid = uid;
  }
}
