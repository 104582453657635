export const environment = {
  production: false,
  authServerHost: 'https://api.max.dev.gametailors.com/auth/',
  casHost: 'https://api.max.dev.gametailors.com/cas',
  // TODO Do not hardcode this
  dataPackage: 'dev_datapackage-84b53537-7d56-4a9e-a806-4da35da6ed7b',
  defaultGame: 'max',
  aspectRatio: { width: 1920, height: 1080 },
  // playGameUrl: 'https://speel.max.dev.gametailors.com/'
  playGameUrl: 'https://webgl.euduca.nl/demo/',

  version_latestBuildNumber: '1036',
  version_latestCommitHash: 'e81418f386b9947333146ab8b290f46190f4ba83',
  version_latestDate: '15/11/2024 14:09',
};
