import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { GeneratedFileMeta } from '@services/types/generated';
import { DataInstance, Tag } from '@services/entities';
import { Color, debounce, fuzzySearch, Logger } from '@services/utils';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Field } from '@services/entities/helpers';
import { BootstrapClass } from '../../../models/types/BootstrapClass';
import { AlertService } from '@services/UI-elements/alert-service';
import {
  getNameOfListInstance,
  getUidOfListInstance,
  isDataInstance,
  isGeneratedFileMeta,
  isVariable,
  ListInstance,
} from '@services/utils/ListInstance';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-instances-list',
  templateUrl: './instances-list.component.html',
  styleUrls: ['./instances-list.component.scss'],
})
export class InstancesListComponent implements OnInit, OnChanges {
  @Input() allInstances: ListInstance[] = [];
  @Input() allTags: Tag[] = [];
  @Input() tagsPerInstance: Record<string, Tag[]> = {};
  @Input() fieldsToDisplay: Field[] = [];
  @Input() listType: string = '';

  @Output() updateFileNameEmitter: EventEmitter<{ uid: string; name: string }> = new EventEmitter<{
    uid: string;
    name: string;
  }>();
  @Output() updateFileAltEmitter: EventEmitter<{ uid: string; alt: string }> = new EventEmitter<{
    uid: string;
    alt: string;
  }>();
  @Output() replaceFileEmitter: EventEmitter<{
    uid: string;
    name: string;
    alt: string;
    file?: File;
  }> = new EventEmitter<{ uid: string; name: string; alt: string; file?: File }>();

  @Output() selectInstanceEmitter: EventEmitter<ListInstance> = new EventEmitter<ListInstance>();
  @Output() duplicateInstanceEmitter: EventEmitter<ListInstance> = new EventEmitter<ListInstance>();
  @Output() downloadInstanceEmitter: EventEmitter<ListInstance> = new EventEmitter<ListInstance>();
  @Output() deleteInstanceEmitter: EventEmitter<ListInstance> = new EventEmitter<ListInstance>();

  @Output() tagSelectedEmitter: EventEmitter<{ tag: Tag; instanceUid: string }> = new EventEmitter<{
    tag: Tag;
    instanceUid: string;
  }>();

  fileMetaOperations: Record<string, { editingName: boolean; editingAlt: boolean; showMedia: boolean }> = {};
  updateFileName = '';
  updateFileAlt = '';
  replaceFileName = '';
  replaceFileAlt = '';
  replaceFileUid = '';
  file?: File;

  debouncedSearch = debounce(this.search.bind(this), 250);
  searchTerm = '';
  filteredInstances: ListInstance[] = [];
  isTagUsedForFilter: Record<string, boolean> = {};

  currentPage: number = 1;
  maxPages: number = 1;
  pageSize: string = '50';
  paginatedInstances: ListInstance[] = [];
  sortingPreferences: Record<string, Sort> = {};

  protected readonly Color = Color;
  protected readonly Object = Object;
  protected readonly isGeneratedFileMeta = isGeneratedFileMeta;
  protected readonly getUidOfListInstance = getUidOfListInstance;
  protected readonly isDataInstance = isDataInstance;
  protected readonly isVariable = isVariable;
  protected readonly getNameOfListInstance = getNameOfListInstance;

  constructor(
    private modalService: NgbModal,
    private alertService: AlertService,
  ) {}

  ngOnInit() {
    this.pageSize = localStorage.getItem('pageSize') ?? '50';
    this.filteredInstances = this.allInstances;

    this.sortingPreferences = JSON.parse(localStorage.getItem('sortingPreferences') ?? '{}');
    if (this.sortingPreferences[this.listType]) {
      this.filterInstances(undefined, this.sortingPreferences[this.listType]);
    }

    this.allInstances.map((instance) => {
      if (isGeneratedFileMeta(instance)) {
        this.fileMetaOperations[instance.uid] = {
          editingName: false,
          editingAlt: false,
          showMedia: false,
        };
      }
    });

    this.allTags.forEach((tag) => {
      this.isTagUsedForFilter[tag.uid] = false;
    });

    this.maxPages = Math.ceil(
      this.filteredInstances.length / (this.pageSize === 'all' ? this.filteredInstances.length : Number(this.pageSize)),
    );
    this.updatePaginatedInstances();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['allInstances']) {
      this.allInstances.map((instance) => {
        if (isGeneratedFileMeta(instance)) {
          this.fileMetaOperations[instance.uid] = {
            editingName: false,
            editingAlt: false,
            showMedia: false,
          };
        }
      });

      this.filteredInstances = this.allInstances;
      this.maxPages = Math.ceil(
        this.filteredInstances.length / (this.pageSize === 'all' ? this.filteredInstances.length : Number(this.pageSize)),
      );
      this.filterInstances();
    }
  }

  onPageChange(newPage: number) {
    if (newPage < 1 || newPage > this.maxPages) return;
    this.currentPage = newPage;
    this.updatePaginatedInstances();
  }

  onChangePageSize() {
    localStorage.setItem('pageSize', this.pageSize);
    this.updatePaginatedInstances();
  }

  search(event?: string) {
    const query = event ?? this.searchTerm;
    this.filterInstances(query);
  }

  filterInstances(query?: string, sort?: Sort) {
    let searchedInstances: ListInstance[];
    if (!query)
      searchedInstances = fuzzySearch(this.searchTerm, this.allInstances, 0.75, (instance) => {
        return getNameOfListInstance(instance);
      });
    else
      searchedInstances = fuzzySearch(query, this.allInstances, 0.75, (instance) => {
        return getNameOfListInstance(instance);
      });

    if (Object.values(this.isTagUsedForFilter).every((tagUsed) => !tagUsed)) {
      this.filteredInstances = searchedInstances;
    } else {
      this.filteredInstances = searchedInstances.filter((instance) =>
        instance.tags.some((tagOfInstance) => this.isTagUsedForFilter[tagOfInstance.uid]),
      );
    }

    if (sort && sort.active && sort.direction !== '') {
      this.sortingPreferences[this.listType] = sort;
      localStorage.setItem('sortingPreferences', JSON.stringify(this.sortingPreferences));

      this.filteredInstances = this.filteredInstances.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'name':
            if ((isGeneratedFileMeta(a) && isGeneratedFileMeta(b)) || (isVariable(a) && isVariable(b))) {
              return this.sortInstances(a.name, b.name, isAsc);
            } else if (isDataInstance(a) && isDataInstance(b)) {
              return this.sortFieldOfDataInstance(a, b, sort);
            }
            return 0;
          case 'modified':
            return this.sortInstances(a.modified, b.modified, isAsc);
          case 'alt':
            if (isGeneratedFileMeta(a) && isGeneratedFileMeta(b)) {
              return this.sortInstances(a.alt ?? '', b.alt ?? '', isAsc);
            } else if (isDataInstance(a) && isDataInstance(b)) {
              return this.sortFieldOfDataInstance(a, b, sort);
            }
            return 0;
          case 'description':
            if (isVariable(a) && isVariable(b)) {
              return this.sortInstances(a.description ?? '', b.description ?? '', isAsc);
            } else if (isDataInstance(a) && isDataInstance(b)) {
              return this.sortFieldOfDataInstance(a, b, sort);
            }
            return 0;
          default:
            if (isDataInstance(a) && isDataInstance(b)) {
              return this.sortFieldOfDataInstance(a, b, sort);
            }
            return 0;
        }
      });
    }

    this.currentPage = 1;
    this.updatePaginatedInstances();
  }

  onFilterTable(tag: Tag) {
    this.isTagUsedForFilter[tag.uid] = !this.isTagUsedForFilter[tag.uid];
    this.filterInstances();
  }

  onFilterAll() {
    Object.keys(this.isTagUsedForFilter).forEach((key) => {
      this.isTagUsedForFilter[key] = true;
    });
    this.filterInstances();
  }

  onFilterNone() {
    Object.keys(this.isTagUsedForFilter).forEach((key) => {
      this.isTagUsedForFilter[key] = false;
    });
    const searchedMedia = fuzzySearch(this.searchTerm, this.allInstances, 0.75, (instance) => {
      return getNameOfListInstance(instance);
    });
    this.filteredInstances = searchedMedia.filter((instance) => instance.tags.length === 0);
    this.currentPage = 1;
    this.maxPages = Math.ceil(
      this.filteredInstances.length / (this.pageSize === 'all' ? this.filteredInstances.length : Number(this.pageSize)),
    );
    this.updatePaginatedInstances();
  }

  onClearFilters() {
    Object.keys(this.isTagUsedForFilter).forEach((key) => {
      this.isTagUsedForFilter[key] = false;
    });
    this.filterInstances();
  }

  getFieldDisplayValue(instance: ListInstance, field: Field): string | undefined {
    if (!isDataInstance(instance)) return undefined;
    return instance.fieldValues[field.fieldId]?.value as string | undefined;
  }

  onUpdateFileName(mediaUid: string) {
    this.updateFileNameEmitter.emit({ uid: mediaUid, name: this.updateFileName });
    this.fileMetaOperations[mediaUid].editingName = false;
  }

  onUpdateFileAlt(mediaUid: string) {
    this.updateFileAltEmitter.emit({ uid: mediaUid, alt: this.updateFileAlt });
    this.fileMetaOperations[mediaUid].editingAlt = false;
  }

  onSubmitFileReplace(modal: NgbModalRef) {
    if (!this.replaceFileUid) {
      return;
    }

    if (!this.replaceFileName) {
      window.alert('Please enter a name for the file');
      return;
    }

    this.replaceFileEmitter.emit({
      uid: this.replaceFileUid,
      name: this.replaceFileName,
      alt: this.replaceFileAlt,
      file: this.file,
    });

    modal.dismiss();

    this.file = undefined;
    this.replaceFileUid = '';
    this.replaceFileName = '';
    this.replaceFileAlt = '';
    return;
  }

  onFileUploadSelected(event: Event) {
    const target = event.target as HTMLInputElement;
    if (!target.files) return;

    this.file = target.files[0];
    if (!this.file) {
      Logger.error('No file selected');
      return;
    }
    this.replaceFileName = this.file.name;
    this.replaceFileAlt = '';
  }

  onOpenReplaceModal(content: TemplateRef<unknown>, media: GeneratedFileMeta): NgbModalRef {
    this.modalService.dismissAll('Closed before opening new modal');
    this.replaceFileName = media.name;
    this.replaceFileAlt = media.alt ?? '';
    this.replaceFileUid = media.uid;
    return this.modalService.open(content, { ariaLabelledBy: 'replace-modal-title', size: 'lg' });
  }

  onTagSelected(tag: Tag, instance: ListInstance) {
    this.tagSelectedEmitter.emit({
      tag,
      instanceUid: getUidOfListInstance(instance),
    });
  }

  onShowAllMediaPreview() {
    this.allInstances.forEach((media) =>
      isGeneratedFileMeta(media) ? (this.fileMetaOperations[media.uid].showMedia = true) : Logger.error('Not a media'),
    );
  }

  onHideAllMediaPreview() {
    this.allInstances.forEach((media) =>
      isGeneratedFileMeta(media) ? (this.fileMetaOperations[media.uid].showMedia = false) : Logger.error('Not a media'),
    );
  }

  onSelectInstance(instance: ListInstance) {
    this.selectInstanceEmitter.emit(instance);
  }

  onDuplicateInstance(instance: ListInstance) {
    this.duplicateInstanceEmitter.emit(instance);
  }

  onDownloadInstance(instance: ListInstance) {
    this.downloadInstanceEmitter.emit(instance);
  }

  async onCopyIdToClipboard(instance: ListInstance) {
    const id = isDataInstance(instance) ? await instance.identifier : getUidOfListInstance(instance);
    navigator.clipboard
      .writeText(id)
      .then(() => {
        Logger.info('Instance Uid copied to clipboard: ' + id);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
        this.alertService.showAlert('Failed to copy instance Uid', BootstrapClass.DANGER);
      });
  }

  onDeleteInstance(instance: ListInstance) {
    this.deleteInstanceEmitter.emit(instance);
  }

  private updatePaginatedInstances() {
    this.maxPages = Math.ceil(
      this.filteredInstances.length / (this.pageSize === 'all' ? this.filteredInstances.length : Number(this.pageSize)),
    );
    if (this.currentPage > this.maxPages) this.currentPage = this.maxPages;
    const start = (this.currentPage - 1) * (this.pageSize === 'all' ? 0 : Number(this.pageSize));
    const end = start + (this.pageSize === 'all' ? this.filteredInstances.length : Number(this.pageSize));
    this.paginatedInstances = this.filteredInstances.slice(start, end);
  }

  private sortFieldOfDataInstance(a: DataInstance, b: DataInstance, sort: Sort): number {
    const fieldA = a.fieldValues[sort.active];
    const fieldB = b.fieldValues[sort.active];
    if (fieldA && fieldB) {
      return this.sortInstances(fieldA.value, fieldB.value, sort.direction === 'asc');
    }
    return 0;
  }

  private sortInstances(a: string, b: string, isAsc: boolean): number {
    return (a.toLowerCase() < b.toLowerCase() ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
