/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GeneratedTag } from './tag';

/**
 *
 */
export interface GeneratedVariable {
  variableRef: string;
  name: string;
  description?: string;
  valueType?: string;
  startValue?: string;
  tags: Array<GeneratedTag>;
  modified: string;
}
