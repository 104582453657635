/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 */
export interface GeneratedFieldEditor {
  editorType: string;
  fieldId: string;
  structId: string;
  showIf?: string;
  showResource?: boolean;
  position: number;
  scope?: string;
  displayField?: string;
}
