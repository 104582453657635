/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GeneratedFieldEditor } from './fieldEditor';
import { GeneratedTag } from './tag';

/**
 *
 */
export interface GeneratedField {
  fieldId: string;
  type: string;
  name: string;
  description?: string;
  required: boolean;
  defaultValue?: string;
  fieldEditor?: GeneratedFieldEditor;
  tags: Array<GeneratedTag>;
}
