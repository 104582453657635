import { Component, HostListener, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, Subscription } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AlertService } from '@services/UI-elements/alert-service';
import { BootstrapClass } from '../../../models/types/BootstrapClass';
import { Title } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UnsavedChangesCheck } from '@guards/saved-changes-checker.guard';
import { LoadingScreenService } from '@services/UI-elements/loading-screen.service';
import { EnumTypeRepository, SelectTypeRepository, StructTypeRepository, TagRepository } from '@services/repositories';
import { EnumType, SelectType, StructType, Tag } from '@services/entities';
import { Field, FieldTypes, SelectTypeOption } from '@services/entities/helpers';
import { environment } from '../../../../environments/environment';
import { GeneratedField, GeneratedFieldEditor, GeneratedTag } from '@services/types/generated';
import { Vector2 } from '@services/utils';
import { ConfirmationModalService } from '@services/UI-elements/confirmation-modal.service';
import { FieldEditorType } from '@services/types/FieldEditorType';

type EditableTable = {
  type: 'StructType' | 'EnumType' | 'SelectType';
  name: string;
  typeId: string;
  description: string;
  isResource: boolean;
  fields: GeneratedField[];
  options: string[];
  selectTypeOptions: SelectTypeOption[];
};

@Component({
  selector: 'app-schema-table',
  templateUrl: './schema-table.component.html',
  styleUrls: ['./schema-table.component.scss'],
})
export class SchemaTableComponent implements OnInit, OnDestroy, UnsavedChangesCheck {
  table?: StructType | SelectType | EnumType; // The table Object
  editableTable: EditableTable;
  tableHistory: EditableTable[] = [];
  tablePointer = 0; // A pointer that indicates the current version of the table
  savePointer = 0; // A pointer that indicates the pointer value of the last save

  schemaTypes: string[] = []; // A list of all possible types in the schema
  filteredSchemaTypes: string[] = []; // A filtered list of all possible types
  structTypes: Record<string, StructType> = {}; // A list of all StructTypes in the schema
  filteredStructsTypes: string[] = []; // A filtered list of all StructTypes
  enumTypes: Record<string, EnumType> = {};
  selectTypes: Record<string, SelectType> = {};

  selectedParentField: Field | undefined; // The selected parent-field in a migration modal
  selectedSubField: Field | undefined; // The selected sub-field in a migration modal
  fieldsFromSubStruct: Field[] = [];

  fieldEditorTypes: { name: string; value: string }[] = [
    { name: 'Default', value: FieldEditorType.Default },
    { name: 'Inline', value: FieldEditorType.Inline },
    { name: 'SeamlessInline', value: FieldEditorType.SeamlessInline },
    { name: 'Hidden', value: FieldEditorType.Hidden },
    { name: 'Chat', value: FieldEditorType.Chat },
    { name: 'CanvasEditor', value: FieldEditorType.CanvasEditor },
    { name: 'ChatEditor', value: FieldEditorType.ChatEditor },
  ];

  allTags: Tag[] = [];
  tagsOfTableFields: Record<string, Tag[]> = {};
  newFields: GeneratedField[] = [];

  openAccordionIndex: number | null = null; // Tracks which accordion is selected

  protected readonly Object = Object;
  protected readonly FieldTypes = FieldTypes;
  protected readonly GeneratedTag = GeneratedTag;
  private routeParamsSub?: Subscription; // Subscription to route parameters
  private tagSubsciption?: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private titleService: Title,
    private modalService: NgbModal,
    private loadingScreenService: LoadingScreenService,
    private structTypeRepository: StructTypeRepository,
    private enumTypeRepository: EnumTypeRepository,
    private selectTypeRepository: SelectTypeRepository,
    private tagRepository: TagRepository,
    private confirmationModalService: ConfirmationModalService,
  ) {
    this.editableTable = {
      type: 'StructType',
      name: '',
      typeId: '',
      description: '',
      isResource: false,
      fields: [],
      options: [],
      selectTypeOptions: [],
    };
  }

  @HostListener('window:beforeunload')
  hasNoUnsavedChanges() {
    return !this.hasUnsavedChanges();
  }

  /**
   * Initializes the component by setting up subscriptions to fetch schema data and to handle route parameters.
   */
  async ngOnInit() {
    await this.loadingScreenService.show(async () => {
      await this.loadSchemaData();
      this.handleRouteParams();
    });
  }

  ngOnDestroy() {
    this.routeParamsSub?.unsubscribe();
    this.tagSubsciption?.unsubscribe();
  }

  /**
   * Loads the schema to map all possible schema types
   */
  async loadSchemaData() {
    const structs = (await this.structTypeRepository.getAll()).flatMap((structType) => [
      `StructRef<${structType.typeId}>`,
      `Struct<${structType.typeId}>`,
    ]);
    (await this.enumTypeRepository.getAll()).forEach((enumType) => {
      this.enumTypes[enumType.typeId] = enumType;
    });
    const enums = Object.values(this.enumTypes).flatMap((enumType) => [`EnumRef<${enumType.typeId}>`, `Enum<${enumType.typeId}>`]);
    (await this.selectTypeRepository.getAll()).forEach((selectType) => {
      this.selectTypes[selectType.typeId] = selectType;
    });
    const selects = Object.values(this.selectTypes).flatMap((selectType) => `Select<${selectType.typeId}>`);
    const lists = [...structs, ...enums, ...selects].map((table) => `List<${table}>`);
    this.structTypes = {};
    (await this.structTypeRepository.getAll()).forEach((structType) => {
      this.structTypes[structType.typeId] = structType;
    });
    this.schemaTypes.push(
      'string',
      'int',
      'bool',
      'float',
      'Vector2',
      'Vector3',
      'Color',
      'Icon',
      'ImageRef',
      'AudioRef',
      'VideoRef',
      'FileRef',
      ...structs,
      ...enums,
      ...selects,
      ...lists,
    );
    this.allTags = await this.tagRepository.getAll();
    this.tagSubsciption = this.tagRepository.cache$.subscribe((tags) => {
      this.allTags = tags.filter((tag) => tag.scope === GeneratedTag.ScopeEnum.Model).sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  /**
   * Initialize the table by fetching table data matching the route parameters
   */
  handleRouteParams() {
    this.routeParamsSub = this.activatedRoute.params.subscribe(async (params) => {
      const typeId = params['typeId'];
      const type = params['type'];

      if (typeId) {
        try {
          switch (type) {
            case 'StructType': {
              this.table = await this.structTypeRepository.get(typeId);
              Object.values(this.table.fields).forEach((field) => {
                this.tagsOfTableFields[field.fieldId] = field.tags;
              });
              this.editableTable = {
                type: 'StructType',
                name: this.table.name,
                typeId: this.table.typeId,
                description: this.table.description ?? '',
                isResource: this.table.isResource,
                fields: await Promise.all(
                  Object.values(this.table.fields).map(async (field) => {
                    return {
                      fieldId: field.fieldId,
                      name: field.name,
                      type: field.type,
                      defaultValue: field.defaultValue,
                      description: field.description,
                      required: field.required,
                      fieldEditor: {
                        fieldId: field.fieldEditor?.fieldId ?? '',
                        structId: field.fieldEditor?.structId ?? '',
                        position: field.fieldEditor?.position ?? 0,
                        showIf: field.fieldEditor?.showIf,
                        showResource: field.fieldEditor?.showResource,
                        editorType: field.fieldEditor?.editorType,
                        scope: field.fieldEditor?.scope,
                        displayField: field.fieldEditor?.displayField,
                      } as GeneratedFieldEditor,
                      tags: field.tags.slice(),
                    } as GeneratedField;
                  }),
                ),
                options: [],
                selectTypeOptions: [],
              };
              this.initEmptyFieldEditors();
              this.orderFields();
              break;
            }
            case 'EnumType': {
              this.table = await this.enumTypeRepository.get(typeId);
              this.editableTable = {
                type: 'EnumType',
                name: this.table.name,
                typeId: this.table.typeId,
                description: this.table.description ?? '',
                isResource: false,
                fields: [],
                options: this.table.options.slice(),
                selectTypeOptions: [],
              };
              break;
            }
            case 'SelectType': {
              this.table = await this.selectTypeRepository.get(typeId);
              this.editableTable = {
                type: 'SelectType',
                name: this.table.name,
                typeId: this.table.typeId,
                description: this.table.description ?? '',
                isResource: false,
                fields: [],
                options: [],
                selectTypeOptions: this.table.options.slice(),
              };
              break;
            }
            default:
              console.warn(`Unknown type: ${type}`);
          }
        } catch (error) {
          this.handleError(error);
          return;
        }
        this.titleService.setTitle(
          `${environment.defaultGame.charAt(0).toUpperCase() + environment.defaultGame.slice(1)} - ${this.table?.name} - CAS`,
        );

        this.tableHistory = [];
        this.adjustDefaultValues();
        this.saveCurrentState();
      }
    });
  }

  /**
   * Delete the table based on its type.
   */
  async deleteTable() {
    if (!this.table) return;

    // Multiple confirmation warnings
    if (!(await firstValueFrom(this.confirmationModalService.confirm(`Are you sure you want to delete ${this.table.typeId}?`)))) return;

    if (
      !(await firstValueFrom(
        this.confirmationModalService.confirm(
          `Deleting ${this.table.typeId} also means deleting all instances (and sub objects) of ${this.table.typeId}. Are you still sure? Maybe you want to make a backup first?`,
        ),
      ))
    )
      return;

    if (
      !(await firstValueFrom(
        this.confirmationModalService.confirm(`WARNING!\n\nYOU ARE ABOUT TO DELETE ALL INSTANCES OF ${this.table.typeId}`),
      ))
    )
      return;

    // Delete the table and user gets referred back to the schema-list
    try {
      if (this.table instanceof StructType) {
        await this.structTypeRepository.delete(this.table);
      } else if (this.table instanceof EnumType) {
        await this.enumTypeRepository.delete(this.table);
      } else {
        await this.selectTypeRepository.delete(this.table);
      }
      this.router.navigate(['/home/data-model']).then();
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.error && error.error.status === 409 && !error.error.message.includes('unique')) {
        if (
          !(await firstValueFrom(
            this.confirmationModalService.confirm(
              `${error.error.error}: ${error.error.message}\n\nDo you want to use the force parameter and delete all values of ${this.table.typeId}?`,
            ),
          ))
        ) {
          return;
        }

        try {
          if (this.table instanceof StructType) {
            await this.structTypeRepository.delete(this.table, true);
          } else if (this.table instanceof EnumType) {
            await this.enumTypeRepository.delete(this.table, true);
          } else {
            await this.selectTypeRepository.delete(this.table, true);
          }
          this.router.navigate(['/home/data-model']).then();
        } catch (e) {
          this.handleError(e);
          return;
        }
      } else {
        this.handleError(error);
      }
    }
  }

  /**
   * Update the table based on its type.
   */
  async updateTable() {
    if (!this.table) {
      return;
    }
    // Adjust default values for potential null bool
    this.adjustDefaultValues();

    // Update the table
    try {
      if (this.table instanceof StructType) {
        this.orderFields();
        this.table.name = this.editableTable.name;
        this.table.description = this.editableTable.description;
        this.table.isResource = this.editableTable.isResource;
        const fields: Record<string, Field> = {};
        for (const field of this.editableTable.fields) {
          if (field.fieldId === '') {
            alert('Field ID cannot be empty');
            return;
          }
          fields[field.fieldId] = await Field.deserialize(field);
        }
        this.table.fields = fields;
        for (const field of this.newFields) {
          this.tagsOfTableFields[field.fieldId] = fields[field.fieldId].tags;
        }

        // We manually save to possibly trigger a 409 error and force save
        await this.structTypeRepository.save(this.table);

        if (this.table.isResource && !this.table.tags.some((tag) => tag.uid === 'tag_defaultResourceTag')) {
          this.table.tags.push(await this.tagRepository.get('tag_defaultResourceTag'));
        } else if (!this.table.isResource && this.table.tags.some((tag) => tag.uid === 'tag_defaultResourceTag')) {
          this.table.tags.splice(
            this.table.tags.findIndex((tag) => tag.uid === 'tag_defaultResourceTag'),
            1,
          );
        }
      } else if (this.table instanceof EnumType) {
        this.table.name = this.editableTable.name;
        this.table.description = this.editableTable.description;
        this.table.options = this.editableTable.options.slice();

        // We manually save to possibly trigger a 409 error and force save
        await this.enumTypeRepository.save(this.table);
      } else {
        this.table.name = this.editableTable.name;
        this.table.description = this.editableTable.description;
        this.table.options = this.editableTable.selectTypeOptions.slice();
      }
    } catch (error) {
      // If table is a structType, we give the user the option to delete all instances of the Struct in order to update it
      if (
        this.table instanceof StructType &&
        error instanceof HttpErrorResponse &&
        error.error &&
        error.error.status === 409 &&
        !error.error.message.includes('unique')
      ) {
        if (
          !(await firstValueFrom(
            this.confirmationModalService.confirm(
              `${error.error.error}: ${error.error.message}\n\nDo you want to use the force parameter and delete all values of this field?`,
            ),
          ))
        ) {
          return;
        }
        if (!(await firstValueFrom(this.confirmationModalService.confirm(`Are you sure you want to delete all values of this field?`)))) {
          return;
        }
        try {
          await this.structTypeRepository.save(this.table as StructType, true);
          this.orderFields();
          if (this.table.isResource && !this.table.tags.some((tag) => tag.uid === 'tag_defaultResourceTag')) {
            this.table.tags.push(await this.tagRepository.get('tag_defaultResourceTag'));
          } else if (!this.table.isResource && this.table.tags.some((tag) => tag.uid === 'tag_defaultResourceTag')) {
            this.table.tags.splice(
              this.table.tags.findIndex((tag) => tag.uid === 'tag_defaultResourceTag'),
              1,
            );
          }
        } catch (e) {
          if (e instanceof HttpErrorResponse) {
            this.handleError(error);
            return;
          }
        }
      } else if (this.table instanceof EnumType && error instanceof HttpErrorResponse && error.error && error.error.status === 409) {
        if (
          !(await firstValueFrom(
            this.confirmationModalService.confirm(
              `${error.error.error}: ${error.error.message}\n\nDo you want to use the force parameter and delete the option anyway?`,
            ),
          ))
        ) {
          return;
        }
        try {
          await this.enumTypeRepository.save(this.table as EnumType, true);
        } catch (e) {
          if (e instanceof HttpErrorResponse) {
            this.handleError(error);
            return;
          }
        }
      } else {
        this.handleError(error);
        return;
      }
    }

    this.router.navigate([`/home/data-model/${this.getTableType(this.table)}/${this.table.typeId}`]).then();
    this.savePointer = this.tablePointer;
    this.newFields = [];
  }

  /**
   * Add an item to the table based on its type.
   */
  addItem() {
    if (this.editableTable.type === 'StructType') {
      const newField = {
        fieldId: '',
        name: '',
        type: '',
        defaultValue: '',
        description: '',
        required: true,
        fieldEditor: {
          editorType: FieldEditorType.Default,
          fieldId: '',
          position: this.editableTable.fields.length,
          showResource: false,
          structId: this.editableTable.typeId,
        },
        tags: [],
      } as GeneratedField;
      this.editableTable.fields.push(newField);
      this.newFields.push(newField);
    } else if (this.editableTable.type === 'EnumType') {
      const newOption = '';
      this.editableTable.options.push(newOption);
    } else if (this.editableTable.type === 'SelectType') {
      const newOption = new SelectTypeOption({
        optionId: '',
        label: '',
      });
      this.editableTable.selectTypeOptions.push(newOption);
    }

    this.saveCurrentState();
  }

  /**
   * Remove an item from the table based on its type.
   * @param identifierToRemove The ID of the item to remove.
   */
  removeItem(identifierToRemove: string | SelectTypeOption) {
    if (this.editableTable.type === 'StructType' && typeof identifierToRemove === 'string') {
      this.editableTable.fields = this.editableTable.fields.filter((field) => field.fieldId !== identifierToRemove);
      this.updateFieldPositions();

      if (this.isNewField(identifierToRemove)) {
        this.newFields = this.newFields.filter((field) => field.fieldId !== identifierToRemove);
      }
    } else if (this.editableTable.type === 'EnumType') {
      this.editableTable.options = this.editableTable.options.filter((option) => option !== identifierToRemove);
    } else {
      this.editableTable.selectTypeOptions = this.editableTable.selectTypeOptions.filter(
        (option) => (option as SelectTypeOption).optionId !== identifierToRemove,
      );
    }

    this.saveCurrentState();
  }

  /**
   * Copies the current table to the clipboard.
   */
  async copyTable() {
    if (!this.table) {
      return;
    }

    if (this.hasUnsavedChanges()) {
      if (
        !confirm(
          'You have unsaved changes. Are you sure you want to copy the current table? This will copy the table version of your last save.',
        )
      ) {
        return;
      }
    }

    const serializedTable = [await this.table.serialize()];
    const tableJSON = JSON.stringify(serializedTable);

    navigator.clipboard
      .writeText(tableJSON)
      .then(() => {
        this.alertService.showAlert('Copied to clipboard successfully!', BootstrapClass.SUCCESS);
        console.log('JSON string copied to clipboard successfully!');
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  /**
   * Checks if a StructType exists based on a field type reference
   * todo: This function should also return true if the reference is an existing SelectType or EnumType
   * @param typeReference
   */
  isExistingStruct(typeReference: string | SelectTypeOption) {
    if (typeof typeReference === 'string') {
      const regex = /^(?:List<)?(\w+(Ref|Select))<(\w+)>>?$/;

      return (
        Object.keys(this.structTypes).includes(typeReference) ||
        Object.keys(this.structTypes).includes(
          typeReference.replace(regex, (match, typePart, refOrSelect, fieldPart) => {
            return fieldPart;
          }),
        )
      );
    }
    return false;
  }

  /**
   * Links the user to a different table
   * @param type The type to visit
   */
  visitTable(type: SelectTypeOption | string) {
    // If the current table is an EnumType, the link to navigate is easy
    if (this.table instanceof EnumType) {
      this.router.navigate([`home/data-model/StructType/${type}`]).then();
    }

    // If the current table is a StructType, we first have to extract the typeId
    else if (this.table instanceof StructType && typeof type === 'string') {
      const regex = /^(?:List<)?(\w+(Ref|Select))<(\w+)>>?$/;

      const navigatePath = type.replace(regex, (match, typePart, refOrSelect, fieldPart) => {
        const replacedTypePart = typePart.endsWith('Ref') ? typePart.replace('Ref', 'Type') : `${typePart}Type`;
        return `${replacedTypePart}/${fieldPart}`;
      });

      this.router.navigate([`/home/data-model/${navigatePath}`]).then();
    }

    this.openAccordionIndex = null;
  }

  /**
   * Updates all Field positions of the table.
   */
  updateFieldPositions() {
    if (this.editableTable.type === 'StructType') {
      // Set the position for each Field based on its current index
      for (const field of this.editableTable.fields) {
        const index: number = this.editableTable.fields.indexOf(field);
        if (field.fieldEditor) {
          // Explicitly checking before access
          field.fieldEditor.position = index;
        }
      }
    }
  }

  async updateFieldId(field: GeneratedField, newFieldId: string) {
    if (!this.isNewField(field.fieldId)) {
      const oldFieldId = field.fieldId;
      this.tagsOfTableFields[newFieldId] = this.tagsOfTableFields[oldFieldId];
      delete this.tagsOfTableFields[oldFieldId];
    }

    field.fieldId = newFieldId;
    this.saveCurrentState();
  }

  /**
   * Initializes FieldEditors for Fields that don't have a FieldEditor yet.
   */
  initEmptyFieldEditors() {
    if (this.editableTable.type === 'StructType') {
      for (const field of this.editableTable.fields) {
        const index = this.editableTable.fields.indexOf(field);
        if (!field.fieldEditor || field.fieldEditor.editorType === undefined) {
          field.fieldEditor = {
            editorType: FieldEditorType.Default,
            fieldId: field.fieldId,
            position: index,
            showIf: undefined,
            showResource: false,
            structId: this.editableTable.typeId,
            scope: undefined,
            displayField: undefined,
          };
          this.saveCurrentState();
        }
      }
    }
  }

  /**
   * Handles a CdkDragDrop event
   * @param event
   */
  drop(event: CdkDragDrop<string[]>) {
    if (this.editableTable.type === 'StructType') {
      moveItemInArray(this.editableTable.fields, event.previousIndex, event.currentIndex);
      this.updateFieldPositions();
      this.saveCurrentState();
    }
  }

  /**
   * Orders all Fields based on their position value
   */
  orderFields() {
    if (this.editableTable.type === 'StructType') {
      if (this.editableTable.fields.some((field) => field.fieldEditor)) {
        this.editableTable.fields.sort((a, b) => {
          // Provide a default position value when fieldEditor is undefined
          const positionA = a.fieldEditor?.position ?? Number.MAX_SAFE_INTEGER;
          const positionB = b.fieldEditor?.position ?? Number.MAX_SAFE_INTEGER;
          return positionA - positionB;
        });
      }
    }
  }

  /**
   * Filters types based on user input
   * @param input
   */
  filterTypes(input: string) {
    this.filteredSchemaTypes = this.schemaTypes.filter((type: string) => type.toLowerCase().includes(input.toLowerCase()));
  }

  /**
   * Filters StructTypes based on user input
   * @param input
   */
  filterStructs(input: string) {
    return (this.filteredStructsTypes = Object.keys(this.structTypes)
      .filter((struct) => struct.includes(input.toLowerCase()))
      .sort());
  }

  /**
   * Saves the current state of the table to the local version history
   */
  saveCurrentState() {
    // Timeout so save happens after model change
    setTimeout(() => {
      this.tableHistory = this.tableHistory.slice(0, this.tablePointer + 1);
      this.tableHistory.push(JSON.parse(JSON.stringify(this.editableTable)));
      this.tablePointer = this.tableHistory.length - 1;
    }, 0);
  }

  /**
   * Tracks accordion index
   * @param index
   */
  trackByFn(index: number): number {
    return index;
  }

  /**
   * Undoes last change
   */
  undo() {
    if (this.canUndo()) {
      this.tablePointer--;
      this.editableTable = JSON.parse(JSON.stringify(this.tableHistory[this.tablePointer]));
    }
  }

  /**
   * Redoes last change
   */
  redo() {
    if (this.canRedo()) {
      this.tablePointer++;
      this.editableTable = JSON.parse(JSON.stringify(this.tableHistory[this.tablePointer]));
    }
  }

  /**
   * Checks if user can Undo a change
   */
  canUndo() {
    return this.tablePointer > 0;
  }

  /**
   * Checks if user can Redo a change
   */
  canRedo() {
    return this.tablePointer < this.tableHistory.length - 1;
  }

  hasUnsavedChanges() {
    return this.savePointer !== this.tablePointer;
  }

  isNewField(fieldId: string) {
    return this.newFields.some((field) => field.fieldId === fieldId);
  }

  onTagSelected(tag: Tag, fieldId: string) {
    const field = this.editableTable.fields.find((field) => field.fieldId === fieldId);
    if (!field) {
      this.alertService.showAlert('Field not found' + fieldId, BootstrapClass.DANGER);
      return;
    }

    if (field.tags.some((t) => t.uid === tag.uid)) {
      field.tags = field.tags.filter((t) => t.uid !== tag.uid);
    } else {
      field.tags.push(tag);
    }
  }

  /**
   * Adjust default values for edge cases
   */
  adjustDefaultValues() {
    if (this.editableTable.type === 'StructType') {
      for (const field of this.editableTable.fields) {
        // Adjust default values of Lists
        if (field.type.startsWith('List')) {
          field.defaultValue = '[]';
        } else if (field.type === 'Vector2' && field.defaultValue && !Vector2.isValidString(field.defaultValue)) {
          field.defaultValue = '0;0';
        } else if (field.type === 'bool' && field.defaultValue !== 'true' && field.defaultValue !== 'false') {
          field.defaultValue = 'false';
        } else if (field.type === 'float' && field.defaultValue?.startsWith('.')) {
          field.defaultValue = '0' + field.defaultValue;
        }
        // Adjust default values of non-required fields, Structs and StructRefs
        else if (
          !field.required ||
          field.type === 'ImageRef' ||
          field.type === 'AudioRef' ||
          field.type === 'VideoRef' ||
          field.type === 'FileRef' ||
          field.type.startsWith('Struct')
        ) {
          field.defaultValue = undefined;
        }
      }
    }
  }

  /**
   * Returns an array of all fields with type StructRef
   */
  getFieldsWithTypeStruct() {
    if (this.table instanceof StructType) {
      return Object.values(this.table.fields).filter((field: Field) => field.type.startsWith('StructRef<'));
    }
    return [];
  }

  /**
   * Returns all fields of a subStruct
   * @param fieldId
   */
  async getFieldsFromSubStruct(fieldId: string) {
    if (this.table instanceof StructType) {
      const type = Object.values(this.table.fields).find((field) => field.fieldId === fieldId)?.type;
      if (!type) {
        this.fieldsFromSubStruct = [];
        return;
      }
      const regex = /Struct(?:Ref)?<([^>]+)>/;
      const match = type.match(regex);
      if (match && !type.startsWith('List')) {
        this.fieldsFromSubStruct = await this.structTypeRepository.get(match[1]).then((struct) => Object.values(struct.fields));
        return;
      }
    }
    this.fieldsFromSubStruct = [];
  }

  /**
   * Creates a fieldMigration entry
   * @param existingFieldId
   * @param newFieldId
   */
  migrateField(existingFieldId: string, newFieldId: string) {
    if (this.table instanceof StructType) {
      if (!this.table.fieldMigrations) {
        this.table.fieldMigrations = [];
      }
      this.table.fieldMigrations.push({
        existingFieldId: existingFieldId,
        newFieldId: newFieldId,
      });
      const existingField = Object.values(this.table.fields).find((field) => field.fieldId == existingFieldId);
      if (existingField) {
        // existingField.fieldId = newFieldId;
        existingField.name = newFieldId.charAt(0).toUpperCase() + newFieldId.slice(1);
      }
      this.saveCurrentState();
    }
  }

  /**
   * Creates a fieldMigration to subStruct entry
   * @param parentField
   * @param subField
   * @param existingField
   */
  async migrateFieldToSubStruct(parentField: Field, subField: Field, existingField: GeneratedField) {
    if (this.table instanceof StructType) {
      if (subField.type != existingField.type) {
        if (
          !(await firstValueFrom(
            this.confirmationModalService.confirm(
              `Subtype field (${subField.type}) does not match existing field (${existingField.type}).

Do you still want to continue?`,
            ),
          ))
        ) {
          return;
        }
      }
      if (!this.table.fieldMigrations) {
        this.table.fieldMigrations = [];
      }

      if (existingField.type) {
        this.table.fieldMigrations.push({
          existingFieldId: existingField.fieldId,
          newFieldId: `${parentField.fieldId}.${subField.fieldId}`,
        });
      }

      if (parentField !== existingField) {
        this.removeItem(existingField.fieldId);
      }
      this.saveCurrentState();
    }
  }

  /**
   * Opens a modal
   * @param content
   */
  openModal(content: TemplateRef<NgbModalRef>) {
    this.modalService.dismissAll('Closed before opening new modal');
    this.modalService.open(content, { ariaLabelledBy: 'upload-modal-title' }).result.then();
  }

  /**
   * Handles any error
   * @param error
   */
  handleError(error: unknown) {
    console.error('An error occurred:', error);

    // Handle HTTP errors
    if (error instanceof HttpErrorResponse) {
      // Server or connection error happened
      if (!navigator.onLine) {
        // Handle offline error
        alert('No Internet Connection\n\nPlease check your network connection.');
        this.alertService.showAlert('No Internet Connection', BootstrapClass.DANGER);
      } else {
        // Handle Http Error (error.status === 403, 404...)
        alert(`Server Error: ${error.status}\n\n${error.error.message}`);
        this.alertService.showAlert('Server Error', BootstrapClass.DANGER);
      }
    } else if (error instanceof TypeError) {
      // Handle client-side or network error
      alert('Error: A network error occurred\n\nPlease try again later.');
      this.alertService.showAlert('Error: A network error occurred', BootstrapClass.DANGER);
    } else if (error instanceof SyntaxError) {
      // Handle Syntax Errors
      alert(`Syntax Error: \n\n${error.message}`);
      this.alertService.showAlert('Syntax Error', BootstrapClass.DANGER);
    } else if (error instanceof Error) {
      // Handle generic error conditions
      alert(`Error\n\n${error.message}`);
      this.alertService.showAlert('Error', BootstrapClass.DANGER);
    } else {
      // Handle unknown errors
      alert('Unknown Error\n\nAn unknown error occurred. Please contact support.');
      this.alertService.showAlert('Unknown Error', BootstrapClass.DANGER);
    }
  }

  /**
   * Handles a keyboardEvent
   * @param event
   */
  @HostListener('window:keydown', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === 'z') {
      event.preventDefault();
      this.undo();
    } else if (event.ctrlKey && event.key === 'y') {
      event.preventDefault();
      this.redo();
    } else if (event.ctrlKey && event.key === 's') {
      event.preventDefault();
      this.updateTable().then();
    }
  }

  protected getTableType(table: StructType | EnumType | SelectType): 'StructType' | 'EnumType' | 'SelectType' {
    if (table instanceof StructType) return 'StructType';
    if (table instanceof EnumType) return 'EnumType';
    return 'SelectType';
  }
}
